import { SERVICE_WORKER_MESSAGE_TYPES } from "src/features/offline/constants"

const postServiceWorkerMessage = ({ type, data }) => {
  if (!Object.values(SERVICE_WORKER_MESSAGE_TYPES).includes(type)) {
    console.warn(`Message type ${type} is not allowed to be posted to the service worker`)
    return
  }

  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active.postMessage({ type, data })
    })
    // eslint-disable-next-line no-restricted-globals
  } else if (self.serviceWorker) {
    // eslint-disable-next-line no-restricted-globals
    self.serviceWorker.postMessage({ type, data })
  }
}

const postSyncAllCachedMeetingPacksMessage = ({ userId }) => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.SYNC_ALL_CACHED_MEETING_PACKS,
    data: { userId }
  })
}

const postSyncCachedMeetingPackMessage = ({ meetingPackId, userId }) => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.SYNC_CACHED_MEETING_PACK,
    data: { meetingPackId, userId }
  })
}

const postSyncCachedPagesMessage = () => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.SYNC_CACHED_PAGES
  })
}

const postClearMeetingPackCacheMessage = ({ meetingPackId }) => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.CLEAR_MEETING_PACK_CACHE,
    data: { meetingPackId }
  })
}

const postClearOutdatedMeetingPacksCacheMessage = () => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.CLEAR_OUTDATED_MEETING_PACKS_CACHE
  })
}
const postPrefetchAssetsMessage = ({ assets }) => {
  postServiceWorkerMessage({
    type: SERVICE_WORKER_MESSAGE_TYPES.PREFETCH_ASSETS,
    data: assets
  })
}

export {
  postServiceWorkerMessage,
  postSyncAllCachedMeetingPacksMessage,
  postSyncCachedMeetingPackMessage,
  postSyncCachedPagesMessage,
  postClearOutdatedMeetingPacksCacheMessage,
  postClearMeetingPackCacheMessage,
  postPrefetchAssetsMessage
}
